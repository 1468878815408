import axios from "axios";
import { getToken } from "../firebase";

const instance = axios.create({
  baseURL: process.env.REACT_APP_PROPERTY_SERVICE_URL,
});

export const reloadToken = async () => {
  const token = await getToken();
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return token;
};

const getFeatures = async () => {
  await reloadToken();
  return instance.get("/filters/features");
};

const postCreateProperty = async (data) => {
  await reloadToken();
  return instance.post("/host/properties", data);
};

const patchEditProperty = async (id, data) => {
  await reloadToken();
  return instance.patch(`/host/properties/${id}`, data);
};

const getProperty = async (id) => {
  await reloadToken();
  return instance.get(`/host/properties/${id}`);
};

export const deletePropertyImage = async (payload) => {
  await reloadToken();
  instance.delete("/host/image", {
    data: payload,
  });
};

const getStatistics = async (payload) => {
  await reloadToken();
  const params = payload || {};
  return instance.get("/host/statistics", { params });
};

const getDailyStatistics = async (payload) => {
  await reloadToken();
  const params = payload || {};
  return instance.get("/host/statistics/daily", { params });
};

const getPotentialTenants = async (id) => {
  await reloadToken();
  return instance.get(`/host/properties/${id}/potential_clients`, {
    params: { page: 1, per: 5 },
  });
};

const getFullPotentialTenants = async (id) => {
  await reloadToken();
  return instance.get("/host/demandeds", {
    params: {
      property_id: id,
      full_features: true,
    },
  });
};

const postHostOffers = async (offers) => {
  await reloadToken();
  return instance.post("/host/offers", {
    data: offers,
    auto_accept: true,
  });
};

const postHostConnect = async (uid) => {
  await reloadToken();
  return instance.post("/host/connections", {
    uid,
    mode: "wishlist",
  });
};

const getContactRequests = async (id, params) => {
  await reloadToken();
  return instance.get(`/host/properties/${id}/contact_requests`, {
    params,
  });
};

const getTenants = async (params) => {
  await reloadToken();
  return instance.get(`/host/contact_requests/tenants`, {
    params,
  });
};

const getOffers = async (id) => {
  await reloadToken();
  return instance.get(`/host/properties/${id}/offers`, {
    params: {
      full_info: true,
    },
  });
};

const getContact = async (id) => {
  await reloadToken();
  return instance.patch(`/host/contact_requests/${id}`, {
    status: "accepted",
  });
};

const getProperties = async (params) => {
  await reloadToken();
  return instance.get("/host/properties", { params });
};

const deleteProperty = async (id) => {
  await reloadToken();
  return instance.delete(`/host/properties/${id}`);
};

const setStatus = async (id, status) => {
  await reloadToken();
  return instance.patch(`/host/properties/${id}/request_active`, {
    status,
  });
};

const duplicateProperty = async (id) => {
  await reloadToken();
  return instance.post(`/host/properties/${id}/duplicate_property`);
};

const getCompetitors = async (id) => {
  await reloadToken();
  return instance.get(`/host/properties/${id}/competitors`);
};

const getBadge = async () => {
  await reloadToken();
  return instance.get("host/badges", {
    params: {
      // objectable_types: NOTIFICATION_TYPES,
      user_type: 'HOST'
    },
  });
};

const getDeals = async () => {
  await reloadToken();
  return instance.get("/host/deals");
};

const postDeal = async (data) => {
  await reloadToken();
  return instance.post("/host/deals", data);
};

const patchDeal = async (id, data) => {
  await reloadToken();
  return instance.patch(`/host/deals/${id}`, data);
};

const getDeal = async (id) => {
  await reloadToken();
  return instance.get(`/host/deals/${id}`);
};

const getDealContacts = async (id) => {
  await reloadToken();
  return instance.get("/host/contact_requests/tenants", {
    params: { deal_id: id },
  });
};
const getAdministratives = async () => {
  await reloadToken();
  return instance.get("cities_detail");
};

const getPlaceSearch = (payload) => {
  return instance.get("web/auto_complete", { params: payload });
};

const getDemands = async (payload) => {
  await reloadToken();
  return instance.get('/host/demands', { params: payload });
};

const getDemandDetails = async (id) => {
  await reloadToken();
  return instance.get(`/host/demandeds/${id}`);
};

export {
  getFeatures,
  postCreateProperty,
  patchEditProperty,
  getProperty,
  getStatistics,
  getDailyStatistics,
  getPotentialTenants,
  getFullPotentialTenants,
  postHostOffers,
  postHostConnect,
  getContactRequests,
  getProperties,
  setStatus,
  deleteProperty,
  duplicateProperty,
  getCompetitors,
  getBadge,
  getContact,
  getOffers,
  getDeals,
  postDeal,
  patchDeal,
  getDeal,
  getDealContacts,
  getAdministratives,
  getTenants,
  getPlaceSearch,
  getDemands,
  getDemandDetails
};
