import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import React from 'react'
import { UserOutlined } from '@ant-design/icons';

const UserInfo = ({ user, suffix, description }) => (
  <div className="d-flex aic">
    <Avatar src={user.photoURL} icon={!user.photoURL ? <UserOutlined /> : null} style={{ marginRight: 8 }} />
    <div>
      <div>
        {user.profile != null && user.profile.fullName ? user.profile.fullName : 'User'}
        { suffix != null && suffix }
      </div>
      <div className="text-grey lh-1">
        <small>
          {
            description == null && user.profile != null && user.profile.phoneNumber
          }
          {
            description != null && description
          }
        </small>
      </div>
    </div>
  </div>
);
UserInfo.defaultProps = {
  suffix: null,
};

UserInfo.propTypes = {
  user: PropTypes.shape().isRequired,
  suffix: PropTypes.node,
};
export default UserInfo;
