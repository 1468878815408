import { combineReducers } from 'redux';
import propertyReducer, { PROPERTY_INITIAL_STATE } from './propertySlice';
import firestoreReducer, { FIRESTORE_INIT_STATE } from './firestoreSlice';
import configReducer, { CONFIG_INIT_STATE } from './configSlice';
import creditReducer, { CREDIT_INIT_STATE } from './creditSlice';

export const INITIAL_STATE = {
  property: PROPERTY_INITIAL_STATE,
  firestore: FIRESTORE_INIT_STATE,
  config: CONFIG_INIT_STATE,
  credit: CREDIT_INIT_STATE,
};

const rootReducer = combineReducers({
  property: propertyReducer,
  firestore: firestoreReducer,
  config: configReducer,
  credit: creditReducer,
});

export default rootReducer;
