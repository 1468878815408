export const DEFAULT_ZOOM = 11;
export const DEFAULT_CENTER = [16.059283, 108.186037];
export const MIN = 500000;
export const MAX = 30000000;
export const STEP = 500000;
export const REACT_APP_MEDIA_URL = 'https://storage.googleapis.com/sumica-media';
export const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;
export const DEFAULT_IMAGES_CHUNK = [
  {
    label: 'overview',
    key: 'overview_photo',
    data: [],
  },
  {
    label: 'properties:rooms',
    key: 'rooms_photo',
    data: [],
  },
  {
    label: 'properties:facilities',
    key: 'facilities_photo',
    data: [],
  },
  {
    label: 'other',
    key: 'other_photo',
    data: [],
  },
];
export const NOTIFICATION_TYPES = ['house_approved', 'house_refused', 'offer_approved', 'favorite_created', 'contact_request_demanding', 'hot_deal_running', 'hot_deal_stopped', 'create_deal'];