import axios from 'axios';
import { getToken } from '../firebase';

const instance = axios.create({
  baseURL: process.env.REACT_APP_NOTIFICATION_SERVICE_URL,
});

export const reloadToken = async () => {
  const token = await getToken();
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return token;
};

const postVerifyPhone = async (data) => {
  await reloadToken();
  return instance.post('otp?type=verify_phone', data);
};
const patchSubmitCode = async (data) => {
  await reloadToken();
  return instance.patch('otp?type=verify_phone', data);
};

const getNotifications = async ({per = 10, page = 1, lang = 'en'}) => {
  await reloadToken();
  return instance.get('notifications', {
    params: {
      per, page, lang,
      user_type: 'HOST'
      // objectable_types: NOTIFICATION_TYPES
    }
  });
}

const updateReadNotification = async (id) => {
  return instance.patch(`notifications/${id}`, {'is_read': true});
}

const updateMarkAllRead = async () => {
  return instance.put('notifications/mark_all_read', {});
}

export {
  postVerifyPhone,
  patchSubmitCode,
  getNotifications,
  updateReadNotification,
  updateMarkAllRead,
};
