import React, { useState, useContext, useEffect } from "react";
import {
  Slider,
  Row,
  Col,
  Alert,
  Radio,
  Input,
  Button,
  Form,
  Modal,
  Select,
  notification,
} from "antd";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import currencyFormat from "../../utils/formats";
import CreditBalance from "../_features/Credit/CreditBalance";
import { PageLoadingContext } from "../../hooks/usePageLoading";
import PropertyItem from "../PropertyItem/PropertyItem";
import DetailsFeatureList from "../FeatureList/DetailsFeatureList";

import { postDeal, patchDeal } from "../../apis/propertyApis";

const { TextArea } = Input;

const DEFAULT_DURATION = 14;
const DEFAULT_PERCENT = 80;
const MIN_PERCENT = 50;
const MAX_PERCENT = 90;
const DEFAULT_CONTACTS = 20;
const MIN_CONTACTS = 10;
const MAX_CONTACTS = 50;

const getPercentPrice = (price, percent) => {
  return currencyFormat(parseInt((percent * price) / 100));
};

const getPercentFromPrice = (property, deal) => {
  if (!deal) return null;
  const percent = parseInt((deal.deal_price * 100) / property.price_vnd);
  return percent;
};

const DealForm = ({ property, deal }) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation(["common", "properties"]);
  const history = useHistory();
  const { showLoading } = useContext(PageLoadingContext);
  const dealPercent = getPercentFromPrice(property, deal);
  const costPerContact = useSelector((state) => state.config.data.deal?.spend);
  const dataFeatures = useSelector((state) => state.config.data.features);
  const [withdrawable, setWithdrawable] = useState(null);
  const [percent, setPercent] = useState(dealPercent || DEFAULT_PERCENT);
  const [contacts, setContacts] = useState(deal?.desired_contacts || DEFAULT_CONTACTS);
  const [enoughCredits, setEnoughCredits] = useState(true);
  const [descLang, setDescLang] = useState(
    i18n.language === "vi" ? "vi" : "en"
  );
  const handleWithdrawableLoaded = (withdrawable) => {
    setWithdrawable(withdrawable);
  };
  const onFormValuesChange = ({ percent, contacts }) => {
    if (percent) {
      setPercent(percent);
    }
    if (contacts) {
      setContacts(contacts);
    }
  };
  useEffect(() => {
    if (withdrawable?.available && costPerContact) {
      setEnoughCredits(withdrawable.available >= costPerContact * contacts);
    }
  }, [contacts, withdrawable, costPerContact]);
  const handleFormSubmitted = async () => {
    showLoading(true);
    const formData = form.getFieldsValue();
    const payload = {
      duration: formData.duration,
      desired_contacts: formData.contacts,
      deal_price: parseInt((formData.percent * property.price_vnd) / 100),
      message: formData.message,
    };
    try {
      await Modal.confirm({
        style: { top: 32 },
        title: t('properties:confirm_information') ,
        content: (
          <div>
           {t('properties:deal_like_this')}
            <div className="mt-16px gradient-box">
              <div className="content">
                <div className="p-12px">
                  <PropertyItem
                    property={property}
                    t={t}
                    dealPrice={getPercentPrice(
                      property.price_vnd,
                      form.getFieldValue("percent")
                    )}
                  />
                </div>
                {form.getFieldValue("message")[i18n.language] != null &&  (
                  <div className="messsage-frame mb-12px">
                    {form.getFieldValue("message")[i18n.language]}
                  </div>
                )}
                <div className="px-12px pb-12px">
                  {property.address?.length >= 4 && (
                    <>
                      <h4 className="mb-0">
                        {property.property_name
                          ? `${property.property_name}, `
                          : ""}
                        {property.address[0]}
                      </h4>
                      <small className="text-grey">
                        {property.address
                          .slice(1, property.address.length)
                          .join(", ")}
                      </small>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        ),
        
        okText: t('confirm'),
        cancelText: t('cancel'),
        onOk: async () => {
          if (deal) {
            await patchDeal(deal.id, payload);
          } else {
            payload.property_id = property.id;
            await postDeal(payload);
          }
          history.push("/deals")
        },
        onCancel: () => {},
      });
    } catch (err) {
      // console.log(err);
      notification.error({
        message: t("failure"),
        description: t("default_err_mess"),
      });
    }
    showLoading(false);
  };
  return (
    <Row>
      <Col lg={24} xl={18} className="site-layout-background">
        <Row gutter={[32, 16]}>
          <Col lg={12}>
            <PropertyItem property={property} t={t} />
            <div className="mt-24px">
              <DetailsFeatureList
                features={property.features}
                t={t}
                i18n={i18n}
                dataFeatures={dataFeatures}
              />
            </div>
          </Col>
          <Col className="border-left" lg={12}>
            <Form
              form={form}
              layout="vertical"
              initialValues={{
                percent: dealPercent || DEFAULT_PERCENT,
                duration: deal?.duration || DEFAULT_DURATION,
                contacts: deal?.desired_contacts || DEFAULT_CONTACTS,
                message: {
                  en: deal?.message?.en,
                  vi: deal?.message?.vi,
                },
              }}
              onValuesChange={onFormValuesChange}
            >
              <h3 className="mt-16px mb-8px">{t("properties:deal_price_d")}</h3>
              <div className="d-flex jcb">
                <div className="text-grey">
                  {getPercentPrice(property.price_vnd, MIN_PERCENT)}
                </div>
                <div className="text-primary">
                  {getPercentPrice(property.price_vnd, percent)}
                </div>
                <div className="text-grey">
                  {getPercentPrice(property.price_vnd, MAX_PERCENT)}
                </div>
              </div>
              <Form.Item required name="percent">
                <Slider
                  min={MIN_PERCENT}
                  max={MAX_PERCENT}
                  tipFormatter={(value) => <div>-{100 - value}%</div>}
                />
              </Form.Item>

              <div className="clearfix mb-8px">
                <div className="float-left">
                  <h3 className="mt-4px mb-4px">{t("properties:message")}</h3>
                </div>
                <div className="float-right">
                  <Select
                    value={descLang}
                    onChange={setDescLang}
                    dropdownMatchSelectWidth={false}
                  >
                    <Select.Option value="vi">Tiếng Việt</Select.Option>
                    <Select.Option value="en">English</Select.Option>
                  </Select>
                </div>
              </div>
              {["vi", "en"].map((lang) => (
                <div
                  key={lang}
                  className={descLang === lang ? "" : "display-none"}
                >
                  <Form.Item
                    name={["message", lang]}
                    rules={[
                      {
                        max: 140,
                        message: t("properties:message_error"),
                      },
                    ]}
                  >
                    <TextArea
                      autoSize={{ minRows: 2 }}
                      placeholder={t("properties:message_placeholder")}
                    />
                  </Form.Item>
                </div>
              ))}
              <h3 className="mt-16px mb-8px">{t("properties:duration")}</h3>
              <Form.Item required name="duration">
                <Radio.Group>
                  <Radio.Button value={7}>7 Days</Radio.Button>
                  <Radio.Button value={14}>14 Days</Radio.Button>
                  <Radio.Button value={30}>30 Days</Radio.Button>
                </Radio.Group>
              </Form.Item>

              <h3 className="mt-16px mb-8px">
                {t("properties:contact_wanted")}
              </h3>
              <div className="text-grey mb-8px">
                <small>
                  {t("properties:contacts_note", { value: costPerContact })}
                </small>
              </div>
              <div className="d-flex jcb">
                <div className="text-grey">{MIN_CONTACTS}</div>
                <div className="text-primary">{contacts}</div>
                <div className="text-grey">{MAX_CONTACTS}</div>
              </div>
              <Form.Item required name="contacts">
                <Slider min={MIN_CONTACTS} max={MAX_CONTACTS} />
              </Form.Item>
              {enoughCredits ? (
                <Alert
                  className="mb-24px"
                  message={t("properties:amount_escrowed", {
                    value: contacts * costPerContact,
                  })}
                  type="info"
                />
              ) : (
                <Alert
                  className="mb-24px"
                  message={t("properties:balance_not_enough")}
                  type="error"
                />
              )}
              <Button
                onClick={handleFormSubmitted}
                type="primary"
                disabled={!enoughCredits}
                className="bg-offer"
                size="large"
              >
                {deal ? t("properties:edit_deal") : t("properties:create_deal")}
              </Button>
            </Form>
          </Col>
        </Row>
        <Row></Row>
      </Col>
      <Col className="detail-sidebar position-relative" lg={24} xl={6}>
        <div className="site-layout-background text-center sticky-sidebar-lg">
          <CreditBalance
            onWithdrawableLoaded={handleWithdrawableLoaded}
            t={t}
            showLoading={showLoading}
          />
          <hr className="mt-16px" />
          {costPerContact && (
            <div className="mt-16px">
              {t("properties:cost_per_deal", {
                value: costPerContact,
              })}
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default DealForm;
