import React, { Suspense, lazy } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import DashboardLayout from "./components/DashboardLayout/DashboardLayout";
import LoadingSpin from "./components/LoadingSpin/LoadingSpin";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import { Provider } from "react-redux";
import store from "./redux/store";
import { AuthContext, useAuth } from "./hooks/useAuth";
import PrivatedRoute from "./components/PrivatedRoute";
import usePageLoading, { PageLoadingContext } from "./hooks/usePageLoading";
import CreateDeal from "./pages/CreateDeal";
import Tenants from "./pages/Tenants";

const Index = lazy(() => import("./pages/Index"));
const NewsFeed = lazy(() => import("./pages/NewsFeed"));
const Create = lazy(() => import("./pages/Create"));
const PropertyDetails = lazy(() => import("./pages/PropertyDetails"));
const Edit = lazy(() => import("./pages/Edit"));
const Credits = lazy(() => import("./pages/Credits"));
const Deals = lazy(() => import("./pages/Deals"));
const EditDeal = lazy(() => import("./pages/EditDeal"));
const PotentialTenant = lazy(() => import("./pages/PotentialTenant"));
// const SentOffers = lazy(() => import("./pages/SentOffers"));
const Contacts = lazy(() => import("./pages/Contacts"));

function App() {
  const isAuthenticated = useAuth();
  const loading = usePageLoading();
  const { PageLoading } = loading;
  return (
    <Provider store={store}>
      <AuthContext.Provider value={isAuthenticated}>
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/404" component={NotFound} />
            <DashboardLayout>
              <PageLoadingContext.Provider value={loading}>
                <Suspense
                  fallback={
                    <div className="p-32px">
                      <LoadingSpin />
                    </div>
                  }
                >
                  <PrivatedRoute exact path="/news-feed" children={<NewsFeed />} />
                  <PrivatedRoute exact path="/" children={<Index />} />
                  <PrivatedRoute path="/create" children={<Create />} />
                  <PrivatedRoute path="/credits" children={<Credits />} />
                  <PrivatedRoute path="/tenants" children={<Tenants />} />
                  <PrivatedRoute exact path="/deals" children={<Deals />} />
                  <PrivatedRoute path="/deals/:id" children={<EditDeal />} />
                  <PrivatedRoute exact path="/p/:id" children={<PropertyDetails />} />
                  <PrivatedRoute path="/p/:id/edit" children={<Edit />} />
                  <PrivatedRoute path="/p/:id/create-deal" children={<CreateDeal />} />
                   <PrivatedRoute
                    path="/p/:id/potential"
                    children={<PotentialTenant />}
                  />
                  {/*
                  <PrivatedRoute
                    path="/p/:id/offers"
                    children={<SentOffers />}
                  /> */}
                  <PrivatedRoute
                    path="/p/:id/contacts"
                    children={<Contacts />}
                  />
                </Suspense>
                <PageLoading />
              </PageLoadingContext.Provider>
            </DashboardLayout>
          </Switch>
        </Router>
      </AuthContext.Provider>
    </Provider>
  );
}

export default App;
