import { Col, Row } from 'antd';

import FeatureItem from '../FeatureItem/FeatureItem';
import PropTypes from 'prop-types';
import React from 'react'

const DetailsFeatureList = ({
  features, i18n, dataFeatures, t,
}) => (
  <>
    <h3 className="mb-16px">{t('properties:facilities_and_amenities')}</h3>
    {
      features && features.length
        ? (
          <Row gutter={[24, 24]}>
            {
              features.map((feature) => (
                <Col key={feature.feature.id} span={8}>
                  {
                    dataFeatures != null
                      ? <FeatureItem feature={feature.feature.name} i18n={i18n} dataFeatures={dataFeatures} status="active" />
                      : feature.feature.name
                  }
                </Col>
              ))
            }
          </Row>
        ) : <div>{t('properties:no_feature')}</div>
    }
  </>
);

DetailsFeatureList.propTypes = {
  features: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  i18n: PropTypes.shape().isRequired,
  dataFeatures: PropTypes.shape().isRequired,
  t: PropTypes.func.isRequired,
};

export default DetailsFeatureList;
