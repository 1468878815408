import "../styles/login.less";

import { Alert, Card } from "antd";
import { FacebookAuthProvider, GoogleAuthProvider, auth } from "../firebase";
import React, { useState, useEffect } from "react";

import LoadingSpin from "../components/LoadingSpin/LoadingSpin";
import { updateUser } from "../apis/firestoreApis";
import { useHistory } from "react-router-dom";

const Login = () => {
  const [state, setState] = useState({
    loading: true,
    error: null,
  });
  const { loading, error } = state;
  const history = useHistory();
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        await updateUser({
          email: user.email,
          photoURL: user.photoURL,
          displayName: user.displayName,
        });
        history.push("/");
      } else {
        setState((state) => ({...state, loading: false}));
      }
    });
    auth
      .getRedirectResult()
      .then(() => {})
      .catch((error) => {
        const errorMessage = error.message;
        setState({ loading: false, error: errorMessage });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = (provider) => {
    setState({ loading: true, error: null });
    switch (provider) {
      case "GOOGLE":
        auth.signInWithRedirect(new GoogleAuthProvider());
        return;
      default:
        auth.signInWithRedirect(new FacebookAuthProvider());
    }
  };

  return (
    <>
      {loading === true ? (
        <div className="p-32px">
          <LoadingSpin />
        </div>
      ) : (
        <div className="d-flex aic jcc" style={{ height: "100vh" }}>
          <Card style={{ width: 364 }}>
            <div className="text-center my-16px">
              <img
                className="mb-32px"
                src="/img/sumica-full.svg"
                height="48"
                alt="logo sumica"
              />
              {error != null && (
                <div className="mb-16px px-16px">
                  <Alert message="Error" description={error} type="error" />
                </div>
              )}
              <div>
                <button
                  type="button"
                  className="facebook-btn"
                  onClick={() => login("FACEBOOK")}
                >
                  <img
                    className="mr-12px"
                    src="/img/Facebook-Icon.svg"
                    height="24"
                    alt="facebook icon"
                  />
                  Login By Facebook
                </button>
              </div>
              <div>
                <button
                  type="button"
                  className="google-btn"
                  onClick={() => login("GOOGLE")}
                >
                  <img
                    className="mr-12px"
                    src="/img/Google-Icon.svg"
                    height="24"
                    alt="google icon"
                  />
                  Login By Google
                </button>
              </div>
            </div>
          </Card>
        </div>
      )}
    </>
  );
};

export default Login;
