import React, { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { HomeOutlined, CreditCardOutlined, PercentageOutlined, UserOutlined, ContainerOutlined } from '@ant-design/icons';
const { Sider } = Layout;

const getActiveSidebar = (location) => {
  switch (location.pathname) {
    case "/credits": return "Credits";
    case "/deals": return "Deals";
    case "/": return "Properties";
    case "/tenants": return "Contacts";
    case "/news-feed": return "News Feed";
    default: return null;
  }
};

const Sidebar = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const [active, setActive] = useState(getActiveSidebar(location));
  useEffect(() => {
    history.listen((location) => {
      const newActive = getActiveSidebar(location);
      setActive(newActive);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Sider
          trigger={null}
          collapsible
          className="main-sider"
          style={{
            overflow: "auto",
            height: "100vh",
          }}
        >
          <Link to="/">
            <div className="logo px-32px py-8px">
              <img alt="logo" src="/img/sumica-full.svg" />
            </div>
          </Link>
          <Menu theme="dark" mode="inline" selectedKeys={[active]}>
            <Menu.Item
              onClick={() => {
                history.push("/");
              }}
              key="Properties"
            >
              <HomeOutlined />
              <span>{t("rent")}</span>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                history.push("/news-feed");
              }}
              key="News Feed"
            >
              <ContainerOutlined />
              <span>{t("news_feed")}</span>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                history.push("/tenants");
              }}
              key="Contacts"
            >
              <UserOutlined />
              <span>{t("contacts")}</span>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                history.push("/credits");
              }}
              key="Credits"
            >
              <CreditCardOutlined />
              <span>{t("credits")}</span>
            </Menu.Item>
            <Menu.Item
              onClick={() => {
                history.push("/deals");
              }}
              key="Deals"
            >
              <PercentageOutlined />
              <span>{t("deals")}</span>
            </Menu.Item>
          </Menu>
        </Sider>
  )
}

export default Sidebar
