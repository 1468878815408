import "./DashboardLayout.less";
import { ConfigProvider, Layout } from "antd";
import React from "react";
import PropTypes from "prop-types";
import enUS from "antd/lib/locale/en_US";
import viVN from "antd/lib/locale/vi_VN";
import Sidebar from "./Sidebar";
import { useTranslation } from "react-i18next";
import Navbar from "./Navbar";

const { Content } = Layout;

const DashboardLayout = ({ children }) => {
  const { i18n } = useTranslation();
  return (
    <ConfigProvider locale={i18n.language === "vi" ? viVN : enUS}>
      <Layout>
        <Sidebar />
        <Layout className="site-layout">
          <Navbar />
          <Content
            style={{
              padding: 16,
              position: "relative",
              height: "calc(100vh - 112px)",
              overflowY: "auto",
            }}
          >
            <div>{children}</div>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default DashboardLayout;
