import { Button, Typography } from 'antd';

import { Link } from 'react-router-dom';
import React from 'react';
import { withTranslation } from 'react-i18next';

const { Title } = Typography;

const NotFound = ({ t }) => <div className="text-center p-32px">
  <Title>404</Title>
  <div className="mb-32px">{t('404_content')}</div>
  <Link to="/">
    <Button type="primary">{t('home_page')}</Button>
  </Link>
</div>
export default withTranslation('common')(NotFound);