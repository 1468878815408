import PropTypes from 'prop-types';
import { REACT_APP_MEDIA_URL } from '../../utils/constants';
import React from 'react'

const FeatureItem = ({
  feature, i18n, dataFeatures, status,
}) => (
  <div className="feature-item">
    <img className="icon-24" alt={feature} src={`${REACT_APP_MEDIA_URL}/features/${feature}_${status}.png`} />
    <span>{dataFeatures[feature] ? dataFeatures[feature].translation[i18n.language || 'en'] : feature}</span>
  </div>
);

FeatureItem.propTypes = {
  feature: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  dataFeatures: PropTypes.shape().isRequired,
  i18n: PropTypes.shape().isRequired,
};

export default FeatureItem;
