import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.less';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://b1c199628b8f49afa7a523f45d61dd4f@o445086.ingest.sentry.io/5420871",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });
}

if (window && window.innerWidth < 1024) {
  ReactDOM.render(
    <div style={{ textAlign: 'center', margin: '32px' }}>
      <h2>
        Please using Sumica application for the best performance
      </h2>
      <a href="https://apps.apple.com/vn/app/sumica/id1475217175" rel="noopener noreferrer">
        <img alt="ios-link" style={{ margin: '8px', height: '48px' }} src="https://sumica.ai/images/app_store_img.png" />
      </a>
      <a href="https://play.google.com/store/apps/details?id=vn.wakumo.sumica" rel="noopener noreferrer">
        <img alt="android-link" style={{ margin: '8px', height: '48px' }} src="https://sumica.ai/images/google_play_img.png" />
      </a>
    </div>,
    document.getElementById('root')
  );
  
} else {
  ReactDOM.render(
      <App />,
    document.getElementById('root')
  );
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
