import PropTypes from 'prop-types';
import React from 'react'
import { Tag } from 'antd';

const CategoryTag = ({ category, t }) => (
  <Tag icon={<img alt={category} src={`/img/${category}.svg`} />} color="#10AC84">
    <span className="ml-8px">{t(`properties:${category}`)}</span>
  </Tag>
);
CategoryTag.propTypes = {
  category: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};
export default CategoryTag;
