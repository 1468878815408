import React, { useEffect, useState } from 'react'
import { auth } from '../firebase';

export const AuthContext = React.createContext();

export const useAuth = () => {
  const [isAuthenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });
  }, []);

  return isAuthenticated;
}