import { useCallback, useEffect } from 'react'
import { setProperty } from "../redux/propertySlice";
import { useHistory, useParams } from 'react-router-dom';
import { getProperty } from '../apis/propertyApis';

const useProperty = ({ dispatch }) => {
  const setProp = useCallback((property) => dispatch(setProperty(property)), [dispatch]);
  const params = useParams();
  const history = useHistory();
  useEffect(() => {
    const getPropertyDetails = async () => {
      try {
        const { data } = await getProperty(params.id);
        if (data.data) {
          setProp(data.data);
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          history.push("/404");
        }
      }
    };
    getPropertyDetails();
    return () => setProp(null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
}

export default useProperty
