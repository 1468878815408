import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

let lang = localStorage.getItem('lang');
if (!lang) lang = 'vi';
i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(Backend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    ns: ["common", "properties", "credits"],
    lng: lang === 'vi' ? 'vi' : 'en',
    fallbackLng: "en",
    // debug: process.env.NODE_ENV === 'development',
    defaultNS: "common",
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
