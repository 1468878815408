import { Empty, Menu } from "antd";
import React, {
  useState,
  useRef,
  useEffect,
} from "react";

import LoadingSpin from "../LoadingSpin/LoadingSpin";
import { formatSimpleDateTime } from "../../utils/formats";
import { getNotifications } from "../../apis/notificationApis";

const NotificationList = ({
  isShowNoti,
  onClickNoti,
  onMarkAllRead,
  unreadNoti,
  t,
  i18n,
}) => {
  const [notifications, setNotifications] = useState(null);
  const [loading, setLoading] = useState(false);
  const pageRef = useRef({ hasMore: true, page: 1 });
  const loadNotifications = async (isLoadingMore) => {
   
    if (!isLoadingMore) {
      pageRef.current.hasMore = true;
      pageRef.current.page = 1;
    }
    if (pageRef.current.hasMore && !loading) {
      setLoading(true);
      const { data } = await getNotifications({
        lang: i18n.language,
        page: pageRef.current.page,
      });
      if (data.data) {
        pageRef.current.hasMore = data.meta && data.meta.next_page;
        pageRef.current.page += 1;
        setLoading(false);
        setNotifications((notifications) =>
          isLoadingMore && notifications
            ? [...notifications, ...data.data]
            : data.data
        );
      }
    }
  };
  const loadRef = useRef(loadNotifications);

  const onScroll = (e) => {
    const el = e.target;
    if (el.scrollTop + el.clientHeight >= el.scrollHeight - 100) {
      loadRef.current(true);
    }
  }

  useEffect(() => {
    if (isShowNoti) {
      loadRef.current();
    }
  }, [isShowNoti]);

  return (
    <Menu
      className="menu-notification"
      onScroll={onScroll}
      onClick={({ key }) => {
        if (key === "mark_all_read") {
          onMarkAllRead();
          return;
        }
        if (notifications) {
          const notification = notifications.find((e) => e.id === key);
          onClickNoti(notification);
          return;
        }
      }}
    >
      {notifications == null && <div></div>}
      {notifications != null && notifications.length === 0 && (
        <div>
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
      {unreadNoti > 0 && (
        <Menu.Item className="mark-all-read" key={"mark_all_read"}>
          {t("mark_all_read")}
        </Menu.Item>
      )}
      {unreadNoti > 0 && <Menu.Divider />}
      {notifications != null &&
        notifications.length > 0 &&
        notifications.map((notification) => (
          <Menu.Item
            className={notification.is_read ? "" : "unread-bg"}
            key={notification.id}
          >
            <div className="d-flex my-8px">
              <div className="notification-icon">
                <img alt="icon" src={notification.icon_url} height="24" />
              </div>
              <div className="pl-12px">
                <div className="notification-content">{notification.body}</div>
                {notification.created_at != null && (
                  <div className="notification-time">
                    {formatSimpleDateTime(notification.created_at)}
                  </div>
                )}
              </div>
            </div>
          </Menu.Item>
        ))}
      {loading && (
        <div>
          <div className="py-16px">
            <LoadingSpin />
          </div>
        </div>
      )}
    </Menu>
  );
};

export default NotificationList;
