import React, { useEffect, useState } from "react";
import { Avatar, Dropdown, Layout, Menu, Space } from "antd";
import i18nInstance from "../../i18n";
import {
  DownOutlined,
} from "@ant-design/icons";
import NotificationDropdown from "./NotificationDropdown";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth } from "../../firebase";

const { Header } = Layout;

const Navbar = () => {
    const history = useHistory();
  const { t, i18n } = useTranslation();
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const signOut = () => {
    auth
      .signOut()
      .then(() => (window.location.href = `${window.location.origin}/login`));
  };
  return (
<Header
            className="site-layout-background header"
            style={{ padding: 0 }}
          >
            <div></div>
            {currentUser != null && (
              <Space className="right-header" size={32}>
                <NotificationDropdown t={t} i18n={i18n} history={history} />
                <Dropdown
                  overlay={
                    <Menu
                      onClick={({ key }) => {
                        localStorage.setItem("lang", key);
                        i18nInstance.changeLanguage(key);
                      }}
                    >
                      <Menu.Item key="en">
                        <img
                          alt="flag"
                          className="mr-8px"
                          src="/img/flag-en.svg"
                          height="20"
                        />
                        English
                      </Menu.Item>
                      <Menu.Item key="vi">
                        <img
                          alt="flag"
                          className="mr-8px"
                          src="/img/flag-vi.svg"
                          height="20"
                        />
                        Tiếng Việt
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <div className="ant-dropdown-link">
                    <img
                      alt="flag"
                      className="mr-8px"
                      src={`/img/flag-${i18n.language}.svg`}
                      height="20"
                    />
                    <DownOutlined />
                  </div>
                </Dropdown>
                <Dropdown overlay={
                      <Menu
                      onClick={({ key }) => {
                        switch (key) {
                          case "3":
                            signOut();
                            break;
                          default:
                            break;
                        }
                      }}
                    >
                      <Menu.Item key="3">
                        <span className="text-danger">{t("logout")}</span>
                      </Menu.Item>
                    </Menu>
                }>
                  <div className="ant-dropdown-link">
                    <Avatar
                      src={currentUser.photoURL}
                      style={{ marginRight: 8 }}
                    />
                    {currentUser.displayName} <DownOutlined />
                  </div>
                </Dropdown>
              </Space>
            )}
          </Header>
  )
}

export default Navbar
