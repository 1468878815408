import 'firebase/firestore';

import { auth } from '../firebase';
import firebase from 'firebase/app';

const fs = firebase.firestore();
const getUser = () => fs.collection('users').doc(auth.currentUser.uid).get();
const updateUser = (data) => fs.collection('users').doc(auth.currentUser.uid).set(data, { merge: true })
const updateUserProfile = (data) => fs.collection('users').doc(auth.currentUser.uid).set({
  profile: data,
}, { merge: true });
export {
  getUser,
  updateUser,
  updateUserProfile,
};
