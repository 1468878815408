import { createSlice } from '@reduxjs/toolkit'
import { getWithdrawable } from '../apis/pointApis';

export const CREDIT_INIT_STATE = {
  withdrawable: null,
  error: null,
};
const creditSlice = createSlice({
  name: 'credit',
  initialState: CREDIT_INIT_STATE,
  reducers: {
    loadWithdrawableRequest(state, action) {
      state.withdrawable = {};
    },
    loadWithdrawableSuccess(state, action) {
      state.withdrawable = action.payload;
    },
    failure(state, action) {
      state.error = action.payload;
    },
  }
});

const { loadWithdrawableRequest, loadWithdrawableSuccess, failure } = creditSlice.actions

export const loadWithdrawable = () => async dispatch => {
  try {
    dispatch(loadWithdrawableRequest());
    const { data } = await getWithdrawable();
    await dispatch(loadWithdrawableSuccess(data.data));
  } catch (err) {
    await dispatch(failure(err));
  }
}

export default creditSlice.reducer