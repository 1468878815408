import { createSlice } from '@reduxjs/toolkit'
import Axios from 'axios';

export const CONFIG_INIT_STATE = {
  data: {
    features: {},
    using_history: {},
    profiles: {},
    offer: {}
  },
};

const configSlice = createSlice({
  name: 'config',
  initialState: CONFIG_INIT_STATE,
  reducers: {
    loadDataSuccess(state, action) {
      state.data = action.payload;
    },
    failure(state, action) {
      state.error = action.payload;
    }
  }
})

const { loadDataSuccess, failure } = configSlice.actions

export const initConfigData = () => async dispatch => {
  try {
    const { data } = await Axios.get(process.env.REACT_APP_PUBLIC_CONFIG);
    await dispatch(loadDataSuccess(data));
  } catch (err) {
    await dispatch(failure(err));
  }
}

export default configSlice.reducer
