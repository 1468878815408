import './DepositAmount.less';

import PropTypes from 'prop-types';
import React from 'react'
import currencyFormat from '../../../utils/formats';

const DepositAmount = ({ amount, isSelected }) => (
  <div className={`amount-container ${isSelected ? 'active' : 'inactive'}`}>
    <p className="credit-text">
      {amount.amount}
      {' '}
      Credits
    </p>
    <p className="actual-text">{currencyFormat(amount.actual)}</p>
  </div>
);

DepositAmount.propTypes = {
  amount: PropTypes.shape().isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default DepositAmount;
