import { Badge, Dropdown } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { getBadge, getProperty } from "../../apis/propertyApis";
import { updateMarkAllRead, updateReadNotification } from "../../apis/notificationApis";

import { BellOutlined } from "@ant-design/icons";
import NotificationList from "./NotificationList";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { setProperty } from "../../redux/propertySlice";
import { useHistory } from "react-router-dom";

const NotificationDropdown = ({t, i18n}) => {
  const intervalRef = useRef(null);
  const [unreadNoti, setUnreadNoti] = useState(0);
  const [isShowNoti, setShowNoti] = useState(false);
  const notiRef = useRef(setUnreadNoti);
  const history = useHistory();
  const dispatch = useDispatch();
  const setProp = (property) => dispatch(setProperty(property));
  useEffect(() => {
    const getUnreadBadge = async () => {
      try {
        const { data } = await getBadge();
        if (data.data) {
          notiRef.current(data.data.unread_noti);
        }
      } catch (err) {
      }
    }
    getUnreadBadge();
    intervalRef.current = setInterval(() => {
      getUnreadBadge();
    }, 10000);
    return () => clearInterval(intervalRef.current);
  }, []);
  const handleOpenNotification = (visible) => {
    setShowNoti(visible);
  }
  const handleClickNoti = async (notification) => {
    try {
      await updateReadNotification(notification.id);
      setShowNoti(false);
      setUnreadNoti(Math.max(unreadNoti - 1 , 0));
      if (notification && notification.data && notification.data.remote_message) {
        const id = notification.data.remote_message.objectable_id;
        let path = `/p/${id}`;
        if (notification.data.remote_message.objectable_type === 'contact_request') {
          path += '/contacts';
        } else if (['hot_deal_running', 'hot_deal_stopped'].includes(notification.data.remote_message.objectable_type)) {
          path =`/deals?time=${Date.now()}`;
        } else if (notification.data.remote_message.objectable_type === 'create_deal') {
          path += `/create-deal`
        }
        const currentUrl = history.location.pathname;
        history.push(path);
        if (path === currentUrl) {
          const { data } = await getProperty(id);
          if (data.data) {
            setProp(data.data);
          }
        }
        
      }
    } catch (e) {
      //ignore
    }
  }

  const handleMarkAllRead = async () => {
    try {
      await updateMarkAllRead();
      setShowNoti(false);
      setUnreadNoti(0);
    } catch (e) {
      //ignore
    }
  }
  return (
    <Dropdown
      placement="bottomCenter"
      visible={isShowNoti}
      overlay={
        <NotificationList
          t={t}
          i18n={i18n}
          isShowNoti={isShowNoti}
          unreadNoti={unreadNoti}
          onClickNoti={handleClickNoti}
          onMarkAllRead={handleMarkAllRead}
        ></NotificationList>
      }
      trigger={["click"]}
      onVisibleChange={handleOpenNotification}
    >
      <div className="ant-dropdown-link">
        <Badge count={unreadNoti}>
          <BellOutlined
            style={{ fontSize: "18px", verticalAlign: "middle" }}
          />
        </Badge>
      </div>
    </Dropdown>
  );
}

NotificationDropdown.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape().isRequired,
}

export default NotificationDropdown;
