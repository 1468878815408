import axios from 'axios';
import { getToken } from '../firebase';

const instance = axios.create({
  baseURL: process.env.REACT_APP_POINT_SERVICE_URL,
});

export const reloadToken = async () => {
  const token = await getToken();
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  return token;
};

export const getWithdrawable = async () => {
  await reloadToken();
  return instance.get('/credits/withdrawable');
};

export const getUsingHistory = async (historyType, currentPage, countPerPage) => {
  await reloadToken();
  return instance.get(`credits/history/${historyType}`, {
    params: { page: currentPage, per: countPerPage },
  });
};

export const getDepositValues = async () => {
  await reloadToken();
  return instance.get('/credit_values/deposit');
};

export const makeDeposit = async (payload) => {
  await reloadToken();
  return instance.post('v2/deposit_credits', payload);
};

export const checkDepositStatus = async (payload) => {
  await reloadToken();
  return instance.patch('check_deposit_credits_status', payload);
};
