import React, { useState } from "react";
import { Col } from 'antd';
import LoadingSpin from "../components/LoadingSpin/LoadingSpin";
export const PageLoadingContext = React.createContext();

const usePageLoading = () => {
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const showLoading = (bool, message) => {
    setLoading(bool);
    if (message) {
      setMessage(message);
    }
  }
  const PageLoading = () => {
    if (isLoading) {
      return (<div className="loading-container">
      <div className="loading-component ">
        <Col>
          <LoadingSpin fontSize={56} />
          {message !== null && <p className="content-text">{message}</p>}
        </Col>
      </div>
    </div>);
    }
    return <div></div>;
  };

  return { PageLoading, showLoading };
};

export default usePageLoading;
