import { createSlice } from '@reduxjs/toolkit'
import { getUser } from '../apis/firestoreApis';

export const FIRESTORE_INIT_STATE = {
  currentUser: null,
};

const firestoreSlice = createSlice({
  name: 'firestore',
  initialState: FIRESTORE_INIT_STATE,
  reducers: {
    loadCurrentUserSuccess(state, action) {
      state.currentUser = action.payload;
    },
    failure(state, action) {

    }
  }
})

const { loadCurrentUserSuccess, failure } = firestoreSlice.actions;

export const loadCurrentUser = () => async (dispatch) => {
  try {
    const res = await getUser();
    dispatch(loadCurrentUserSuccess(res.data()))
  } catch (err) {
    dispatch(failure(err))
  }
}

export default firestoreSlice.reducer;
