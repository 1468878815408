import './ImagesCarousel.less';

import { Carousel } from 'antd';
import PropTypes from 'prop-types';
import React from 'react'

const ImagesCarousel = ({ images, height, autoplay }) => (
  <Carousel autoplay={autoplay}>
    {
      images.map((image) => (
        <div
          key={image}
        >
          <div
            key={image.public_id}
            className="image-wrapper"
            style={{
              background: `url(${image.normal_url}) no-repeat`,
              borderRadius: 6
              // height,
            }}
          />
          {/* <img alt={image.public_id} className="image" src={image.normal_url} /> */}
        </div>
      ))
    }
  </Carousel>
);

ImagesCarousel.defaultProps = {
  autoplay: false,
};

ImagesCarousel.propTypes = {
  autoplay: PropTypes.bool,
  height: PropTypes.number.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    public_id: PropTypes.string,
    normal_url: PropTypes.string,
  })).isRequired,
};

export default ImagesCarousel;
