import React from "react";
import {
  Breadcrumb,

} from "antd";
import useProperty from "../hooks/useProperty";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import DealForm from "../components/DealForm/DealForm";




const CreateDeal = () => {
  const { t } = useTranslation(["common", "properties"]);
  const dispatch = useDispatch();
  useProperty({ dispatch });
  const property = useSelector((state) => state.property.property);
  return (
    <div>
      {property != null && (
        <>
          <Breadcrumb className="mb-16px">
            <Breadcrumb.Item>
              <Link to="/">{t("rent")}</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {property && (
                <Link to={`/p/${property.id}`}>
                  {property.address_number ? `${property.address_number} ` : ""}
                  {property.address
                    ? property.address[0]
                    : t("properties:property_details")}
                </Link>
              )}
            </Breadcrumb.Item>
            <Breadcrumb.Item>{t("properties:create_deal")}</Breadcrumb.Item>
          </Breadcrumb>
          <DealForm property={property} />
        </>
      )}
    </div>
  );
};

export default CreateDeal;
