// import { applyMiddleware, createStore } from 'redux';
// import createSagaMiddleware from 'redux-saga';

// import rootReducer, { INITIAL_STATE } from './index';
// import rootSaga from '../sagas';

// const bindMiddleware = (middleware) => {
//   if (process.env.NODE_ENV !== 'production') {
//     const { composeWithDevTools } = require('redux-devtools-extension');
//     return composeWithDevTools(applyMiddleware(...middleware));
//   }
//   return applyMiddleware(...middleware);
// };
// const sagaMiddleware = createSagaMiddleware();
// const store = createStore(rootReducer, INITIAL_STATE, bindMiddleware([sagaMiddleware]));
// sagaMiddleware.run(rootSaga);

// export default store;
import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './index'
import { initConfigData } from './configSlice'

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
})

store.dispatch(initConfigData())

export default store