import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../hooks/useAuth'
import LoadingSpin from './LoadingSpin/LoadingSpin';

const PrivatedRoute = ({ children, ...rest }) => {
  const isAuthenticated = useContext(AuthContext);
  return (
    <Route {...rest} render={() => {
      if (isAuthenticated === true) {
        return children;
      }
      if (isAuthenticated === false) {
        return <Redirect
        to={{
          pathname: "/login",
        }}
      />
      }
      return (
        <div className="p-32px">
          <LoadingSpin />
        </div>
      ); 
    }}>
      
    </Route>
  )
}

export default PrivatedRoute
