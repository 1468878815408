import PropTypes from 'prop-types';
import React from 'react'

const { Spin } = require('antd');
const { LoadingOutlined } = require('@ant-design/icons');

const LoadingSpin = ({ fontSize = 24 }) => (
  <div className="text-center">
    <Spin indicator={<LoadingOutlined style={{ fontSize }} spin />} />
  </div>
);

LoadingSpin.propTypes = {
  fontSize: PropTypes.number,
};

export default LoadingSpin;
