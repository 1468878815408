import "./PropertyItem.less";

import { Col, Row, Tag } from "antd";

import CategoryTag from "../CategoryTag/CategoryTag";
import ImagesCarousel from "../ImagesCarousel/ImagesCarousel";
import PropTypes from "prop-types";
import React from "react";
import currencyFormat from "../../utils/formats";
import { PercentageOutlined } from "@ant-design/icons";

const PropertyItem = ({ property, t, dealPrice }) => (
  <div className="relative">
    {property.category != null && (
      <div className="category-in-item">
        <CategoryTag category={property.category.name} t={t} />
      </div>
    )}
    {dealPrice != null && (
      <div className="deal-tag">
        <Tag
          icon={<PercentageOutlined></PercentageOutlined>}
          className="bg-offer text-white mr-0"
        >
          Hot Deal
        </Tag>
      </div>
    )}
    {property.active_images.length > 0 ? (
      <ImagesCarousel height={150} images={property.active_images} />
    ) : (
      <div className="no-p-img mb-16px" />
    )}
    <Row>
      <Col span={15}>
        {dealPrice ? (
          <h3 className="fwb mb-0 text-offer">{dealPrice}</h3>
        ) : (
          <h3 className="fwb mb-0 text-primary">
            {property.price_vnd
              ? currencyFormat(property.price_vnd)
              : t("properties:no_price")}
          </h3>
        )}
        {dealPrice == null &&
          property.property_name == null &&
          property.floors != null && (
            <div>
              {property.category.name === "house"
                ? t("properties:floors_count", { value: property.floors })
                : t("properties:floor_index", { value: property.floors })}
            </div>
          )}
        {dealPrice != null && (
          <span className="text-line-through text-grey">
            {currencyFormat(property.price_vnd)}
          </span>
        )}
        {dealPrice == null && property.property_name != null && (
          <div>{property.property_name}</div>
        )}
      </Col>
      <Col className="general-info" span={3}>
        <img
          alt="bedrooms"
          src="/img/bedrooms.svg"
          className="list-general-icon"
        />
        <div>{property.bedrooms || "-"}</div>
      </Col>
      <Col className="general-info" span={3}>
        <img
          alt="bathrooms"
          src="/img/bathrooms.svg"
          className="list-general-icon"
        />
        <div>{property.bathrooms || "-"}</div>
      </Col>
      <Col className="general-info" span={3}>
        <img alt="square" src="/img/square.svg" className="list-general-icon" />
        <div>{property.square || "-"}</div>
      </Col>
    </Row>
  </div>
);

PropertyItem.propTypes = {
  property: PropTypes.shape({
    active_images: PropTypes.arrayOf(PropTypes.shape()),
    bathrooms: PropTypes.number,
    bedrooms: PropTypes.number,
    category: PropTypes.shape({
      name: PropTypes.string,
    }),
    floors: PropTypes.number,
    price_vnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    square: PropTypes.number,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default PropertyItem;
