import { createSlice } from '@reduxjs/toolkit'
import { getFeatures } from '../apis/propertyApis';

export const PROPERTY_INITIAL_STATE = {
  property: null,
  features: [],
  error: null,
};

const propertySlice = createSlice({
  name: 'property',
  initialState: PROPERTY_INITIAL_STATE,
  reducers: {
    setProperty(state, action) {
      state.property = action.payload;
    },
    setPropertyStatus(state, action) {
      state.property.status = action.payload;
    },
    loadFeaturesSuccess(state, action) {
      state.features = action.payload;
    },
    failure(state, action) {
    },
  }
});

const { loadFeaturesSuccess, failure } = propertySlice.actions

export const loadFeatures = () => async dispatch => {
  try {
    const res = await getFeatures();
    if (res.data) {
      await dispatch(loadFeaturesSuccess(res.data.data))
    }
  } catch (err) {
    await dispatch(failure(err));
  }
}

export const { setProperty, setPropertyStatus } = propertySlice.actions
export default propertySlice.reducer