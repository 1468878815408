import './CreditBalance.less';

import {
  Button,
  Col,
  Modal,
  Row,
} from 'antd';
import { getDepositValues, makeDeposit } from '../../../apis/pointApis';

import React, { useState } from 'react';
import DepositAmount from './DepositAmount';
import LoadingSpin from '../../LoadingSpin/LoadingSpin';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { loadWithdrawable } from '../../../redux/creditSlice';
import { useEffect } from 'react';

const CreditBalance = ({onWithdrawableLoaded, showLoading, t, isRow = false}) => {
  const [shouldShowDepositModal, setShowDepositModal] = useState(false);
  const [depositValues, setDepositValues] = useState(null);
  const [selectedAmount, setSelectedAmount] = useState(null);
  const withdrawable = useSelector(state => state.credit.withdrawable);
  const dispatch = useDispatch();
  const loadWithdrawableData = () => dispatch(loadWithdrawable());
  useEffect(() => {
    loadWithdrawableData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (withdrawable && onWithdrawableLoaded) {
      onWithdrawableLoaded(withdrawable)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawable]);

  const showDepositModal = () => {
    setShowDepositModal(true);
    if (depositValues != null) return;
    loadDepositValues();
  };

  const hideDepositModal = () => {
    setShowDepositModal(false);
  };

  const loadDepositValues = async () => {
    const { data } = await getDepositValues();
    if (data.data) {
      setDepositValues(data.data);
    }
  };

  const onAmountSelected = (value) => {
    if (selectedAmount === value.amount) return;
    setSelectedAmount(value.amount);
  };

  const onDepositClick = async () => {
    hideDepositModal();
    showLoading(true, t('credits:redirect_to_ngan_luong_paygate'));
    const { data } = await makeDeposit({
      total_amount: selectedAmount,
      return_url: `${window.location.origin}/credits`,
    });
    if (data.data) {
      window.location.href = data.data.checkout_url;
    }
  };
  return (
    <Col className="text-center">
      {
        isRow ? <h3 className="text-left">
          <span className="fwn mr-8px">{t('credits:current_balance')}</span>
          <span className="text-primary mr-16px">{withdrawable != null && withdrawable.available} Credits</span>
          <Button
            type="primary"
            size="small"
            // className="deposit-btn"
            onClick={showDepositModal}
          >
            {t('credits:deposit_credits')}
          </Button>
        </h3>
        : <>
          <h3>{t('credits:current_balance')}</h3>
          {withdrawable == null ? (
            <LoadingSpin />
          ) : (
            <h2 className="text-primary">
              {withdrawable != null && withdrawable.available}
              {' '}
              Credits
            </h2>
          )}
          <Button
            type="primary"
            className="deposit-btn"
            onClick={showDepositModal}
          >
            {t('credits:deposit_credits')}
          </Button>
        </>
      }
      <Modal
        width={500}
        title={t('credits:choose_deposit_amount')}
        visible={shouldShowDepositModal}
        onCancel={hideDepositModal}
        centered="true"
        bodyStyle={{ padding: '16px 16px 0px 16px' }}
        footer={(
          <Button
            type="primary"
            block
            disabled={selectedAmount == null}
            onClick={onDepositClick}
          >
            {t('credits:deposit_by_ngan_luong_paygate')}
          </Button>
        )}
      >
        {depositValues == null ? (
          <LoadingSpin />
        ) : (
          <Row gutter={[16, 16]}>
            {depositValues.map((value) => (
              <Col key={value.amount} span={8}>
                <div onClick={() => onAmountSelected(value)}>
                  <DepositAmount
                    amount={value}
                    isSelected={selectedAmount === value.amount}
                  />
                </div>
              </Col>
            ))}
          </Row>
        )}
      </Modal>
    </Col>
  );
}


CreditBalance.defaultProps = {
  withdrawable: null,
  loadWithdrawableData: null,
  onWithdrawableLoaded: null,
};

CreditBalance.propTypes = {
  withdrawable: PropTypes.shape(),
  loadWithdrawableData: PropTypes.func,
  onWithdrawableLoaded: PropTypes.func,
  showLoading: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default CreditBalance;
