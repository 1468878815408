import { Col, Row, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatSimpleDateTime } from "../utils/formats";
import UserInfo from "../components/UserInfo/UserInfo";
import { getTenants } from "../apis/propertyApis";

const Tenants = () => {
  const { t, i18n } = useTranslation([
    "common",
    "properties",
    "features",
    "credits",
  ]);
  const dataProfiles = useSelector((state) => state.config.data.profiles);
  const [contacts, setContacts] = useState(null);
  useEffect(() => {
    const loadRequests = async () => {
      const { data } = await getTenants(null, {
        status: ["accepted"],
      });
      if (data.data) {
        setContacts(data.data);
      }
    };
    loadRequests();
  }, []);

  const columns = useRef([
    {
      key: "request",
      dataIndex: "request",
      render: (request) => {
        return (
          <UserInfo
            user={request.user}
            description={formatSimpleDateTime(request.requestTime)}
          />
        );
      },
    },
    {
      dataIndex: "label",
      key: "source",
      render: (label) =>
      t(`properties:from_${label}`),
    },
    {
      dataIndex: "tenancy",
      key: "tenancy",
      render: (profile) => {
        const { language } = i18n;
        const { member_options, move_options, term_options } = dataProfiles;
        if (!member_options || !move_options || !term_options) return <div></div>;
        return (
          <div style={{ width: 320 }}>
            {renderProfileItem(
              t("properties:members"),
              profile.member,
              member_options,
              language,
              '-'
            )}
            {renderProfileItem(
              t("properties:moving"),
              profile.move,
              move_options,
              language,
              '-'
            )}
            {renderProfileItem(
              t("properties:staying"),
              profile.term,
              term_options,
              language,
              '-'
            )}
          </div>
        );
      },
    },
    {
      dataIndex: "contact",
      key: "contact",
      render: (contact) => (
        <div>{contact.phoneNumber}</div>
      ),
    },
  ]);
  const renderProfileItem = (title, value, config, language, unset) => (
    <Row>
      <Col span={12}>{title}:</Col>
      <Col span={12}>
        {config[value] && config[value].translation[language]
          ? config[value].translation[language]
          : unset}
      </Col>
    </Row>
  );


  useEffect(() => {
    const setColumnsTitle = (columns) => {
      columns.forEach((e) => {
        e.title = t(`properties:c_table_${e.key}`);
      });
    };
    setColumnsTitle(columns.current);
  }, [t]);

  return contacts != null ? (
    <div className="site-layout-background">
      <Table
        columns={columns.current}
        scroll={{ x: "max-content" }}
        dataSource={contacts.map((e) => ({
          label: e.label,
          tenancy: e.profile,
          contact: {
            phoneNumber: e.profile?.phoneNumber,
            id: e.id,
            status: e.status,
          },
          request: {
            user: {
              photoURL: e.photoURL,
              uid: e.uid,
              profile: e.profile,
            },
            requestTime: e.updated_at,
          },
          key: e.id,
        }))}
      />
    </div>
  ) : (
    <div></div>
  );
};

export default Tenants;
